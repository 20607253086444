<script lang="ts" setup>
const authStore = useAuthStore();
const route = useRoute();
const { justLoggedIn } = storeToRefs(useUiStore());

watch(
  () => route.path,
  (newRoute, oldRoute) => {
    if (justLoggedIn.value && (oldRoute === '/dashboard' || oldRoute === '/onboarding')) {
      justLoggedIn.value = false;
    }
  },
);
</script>

<template>
  <CoreSessionTimeoutModal />
  <div>
    <div v-if="authStore.isloggingOut">
      <div />
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>
